<template>
  <section>
    <TopBanner
      title="Resources"
      :src="require('@/assets/images/banners/banner_resource.jpg')"
    />
    <v-container class="content-container">
      <div v-if="resources && resources.length > 0" id="resources">
        <div class="text-center">
          <p class="primary--text text-h5 text-sm-h3 font-weight-bold">
            Information for Product Matrix & Rate Here
          </p>
          <p class="primary--text text-body-1 text-sm-h5 font-weight-light">
            Join the New Wave Lending.
          </p>
        </div>
        <div>
          <v-row>
            <v-col
              v-for="category in categories"
              :key="category"
              cols="12"
              md="6"
            >
              <v-card flat tile color="primary" class="py-2 text-center" dark>
                {{ category }}
              </v-card>
              <v-card flat tile color="beige" class="py-2" outlined>
                <v-card
                  max-width="400"
                  class="mx-auto"
                  color="transparent"
                  tile
                  flat
                >
                  <ul>
                    <li
                      v-for="item in resources.filter(
                        (x) => x.category === category
                      )"
                      :key="`resources-forms-${item.name}`"
                      class="text-body-1 my-2"
                    >
                      <a
                        class="black--text font-weight-regular text-decoration-none"
                        href="javascript:void(0)"
                        @click="showGuideline(item.src)"
                      >
                        {{ item.name }}
                      </a>
                    </li>
                  </ul>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else>
        <div v-if="resources">
          <span class="error--text"> Error </span>
        </div>
        <div v-else>
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                class="mx-auto"
                type="image@3"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                class="mx-auto"
                type="image@3"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
    <PdfViewer :src="targetPdfPath" />
    <Footer />
  </section>
</template>

<!-- <style>
  .v-content .v-card.v-sheet {
      background-color: #ffffff !important;
      border-left: 5px solid red !important
  }
</style> -->

<script>
import TopBanner from "@/components/TopBanner";
import PdfViewer from "@/components/PdfViewer";
import Footer from "@/views/layouts/Footer";
import axios from "axios";

export default {
  components: {
    TopBanner,
    PdfViewer,
    Footer,
  },
  async created() {
    this.resources = await this.getResources();
  },
  data() {
    return {
      categories: null,
      resources: null,
      targetPdfPath: null,
    };
  },
  methods: {
    async getResources() {
      var res = await axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/api/NWAPP/InfoResource/All/VM`,
          {
            headers: {
              Authorization: `Basic ${btoa(
                this.$store.state.basicAuth.userName +
                  ":" +
                  this.$store.state.basicAuth.password
              )}`,
            },
          }
        )
        .then((resp) => {
          this.categories = [
            ...new Set(resp.data.map((x) => x.category).sort()),
          ];
          return resp.data;
        })
        .catch(() => {
          return [];
        });

      return res;
    },
    showGuideline(path) {
      this.targetPdfPath = path;
      this.$modal.show("pdf-viewer");
    },
  },
};
</script>
